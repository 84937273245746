import React, { useState, useRef } from 'react'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { WrapperSection } from '../../wrappers'
import ScrollContainer from 'react-indiana-drag-scroll'

import { useOnScreen } from '../../hooks'

const TabServices = ({ data, theme }) => {
  const { slug_section, tabs, title } = data
  const [activeTab, setActiveTab] = useState(1)
  const refContainer = useRef()
  const onScreen = useOnScreen(refContainer)
  const headlineH2 = 'h2'

  return (
    <WrapperSection id={slug_section} extraCss={{ contentVisibility: 'auto' }}>
      <T
        d={32}
        m={32}
        o={0.16}
        bold
        condensed
        upper
        variant={headlineH2}
        extraCss={sHeadline}
        onScreen={onScreen}>
        {title}
      </T>
      <div
        ref={refContainer}
        css={[
          sContainer,
          sTheme(theme),
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
        ]}>
        <div css={sTabs}>
          <ScrollContainer nativeMobileScroll={true} className="inidian-scroll">
            {tabs.map((tab, id) => (
              <div
                key={id}
                onClick={() => setActiveTab(id)}
                onKeyPress={() => setActiveTab(id)}
                role="button"
                tabIndex={0}
                css={[
                  sTabContainer,
                  activeTab === id && sTabContainer.active,
                  activeTab === id
                    ? {
                        color: colors.white,
                        backgroundColor: tab.color || colors[theme].main,
                      }
                    : {
                        color: 'inherit',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: tab.color
                            ? tab.color.concat(alpha[8])
                            : colors[theme].main.concat(alpha[8]),
                          color: tab.color
                            ? tab.color.concat(alpha[100])
                            : colors[theme].main,
                        },
                      },
                ]}>
                {tab.titles.map((title, id) => (
                  <span
                    className={`${[
                      title.smaller ? 'sTabTextSmaller' : 'sTabTextLarger',
                    ]}`}
                    key={id}
                    css={[sTabContainer.span]}>
                    {title.text}
                  </span>
                ))}
              </div>
            ))}
          </ScrollContainer>
        </div>

        {/* <div css={sBottomContainer} key={activeTab}>
          <div css={[sLeftContainer]}>
            <div>
              <T
                d={32}
                m={24}
                mb={1}
                bold
                condensed
                variant="h4"
                extraCss={{ color: tabs[activeTab].color }}>
                {tabs[activeTab].titles.map((title, id) => (
                  <span key={id}>{title.text} </span>
                ))}
              </T>
              <div>
                {tabs[activeTab].descs?.length > 0 &&
                  tabs[activeTab].descs.map((desc, id) => (
                    <T d={14} o={0.3} key={id} extraCss={{ marginBottom: 32 }}>
                      {desc.text}
                    </T>
                  ))}
              </div>
            </div>
            {tabs[activeTab].tools?.length > 0 && (
              <div css={sTools}>
                {tabs[activeTab].tools?.map((tool, id) => (
                  <img
                    key={id}
                    src={tool.image.publicURL}
                    type="image/svg+xml"
                    alt={tool.image.name}
                  />
                ))}
              </div>
            )}
          </div>
          <div css={sRightContainer}>
            {tabs[activeTab].images?.length > 0 &&
              tabs[activeTab].images[0].image?.childImageSharp?.fluid && (
                <Img
                  style={sImage1Desktop}
                  fluid={tabs[activeTab].images[0].image.childImageSharp.fluid}
                  alt={tabs[activeTab].titles[0].name}
                />
              )}
            {tabs[activeTab].images?.length > 1 &&
              tabs[activeTab].images[1].image?.childImageSharp?.fluid && (
                <div css={{ [s.sm_down]: { display: 'none' } }}>
                  <Img
                    style={sImage2Desktop}
                    fluid={
                      tabs[activeTab].images[1].image.childImageSharp.fluid
                    }
                    alt={tabs[activeTab].titles[0].name}
                  />
                </div>
              )}
          </div>
        </div> */}

        {/* ///////////////// */}
        <div css={{ position: 'relative', transition: 'height 1s ease-out' }}>
          {/* THIS IS JUST FOR HEIGHT */}
          {tabs
            .filter((tab, id) => id === activeTab)
            .map((tab, id) => (
              <div css={[sBottomContainer, { opacity: 0 }]} key={id}>
                <div css={[sLeftContainer]}>
                  <div>
                    <T d={32} m={24} mb={1} bold condensed>
                      {tab.titles.map((title, id) => (
                        <span key={id}>{title.text} </span>
                      ))}
                    </T>
                    <div>
                      {tab.descs?.length > 0 &&
                        tab.descs.map((desc, id) => (
                          <T
                            d={14}
                            key={id}
                            extraCss={sDescriptionTheme(theme)}>
                            {desc.text}
                          </T>
                        ))}
                    </div>
                    {tab.tools?.length > 0 && (
                      <div css={{ paddingBottom: '3rem' }} />
                    )}
                    <div
                      css={{
                        [s.xs]: {
                          height: `calc(100vw - ${
                            globals.spacing.outside.mobile * 2
                          }px)`,
                        },
                        [s.sm_only]: {
                          height: 350,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          {/* RENDERS ALL BUT SHOWS ONLY ONE */}
          {tabs.map((tab, id) => (
            <div
              css={[
                sBottomContainer,
                { transition: 'opacity 0.25s ease-out' },
                { position: 'absolute', top: 0, left: 0 },
                { opacity: id === activeTab ? 1 : 0 },
              ]}
              key={id}>
              <div css={[sLeftContainer]}>
                <div>
                  <T
                    d={32}
                    m={24}
                    mb={1}
                    bold
                    condensed
                    variant="h4"
                    extraCss={{ color: tab.color }}>
                    {tab.titles.map((title, id) => (
                      <span key={id}>{title.text} </span>
                    ))}
                  </T>
                  <div>
                    {tab.descs?.length > 0 &&
                      tab.descs.map((desc, id) => (
                        <T d={14} key={id} extraCss={sDescriptionTheme(theme)}>
                          {desc.text}
                        </T>
                      ))}
                  </div>
                </div>
                {/* {tab.tools?.length > 0 && (
                  <div css={sTools}>
                    {tab.tools?.map((tool, id) => (
                      <img
                        key={id}
                        src={tool.image.publicURL}
                        type="image/svg+xml"
                        alt={tool.image.name}
                      />
                    ))}
                  </div>
                )} */}
              </div>
              <div css={sRightContainer}>
                <div css={sImage1}>
                  {tab.images?.length > 0 &&
                    tab.images[0].image?.childImageSharp?.fluid && (
                      <Img
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: globals.roundness.pictures.mobile,
                        }}
                        fluid={tab.images[0].image.childImageSharp.fluid}
                        alt={tab.titles[0].name}
                      />
                    )}
                </div>
                {tab.images?.length > 1 &&
                  tab.images[1].image?.childImageSharp?.fluid && (
                    <div css={{ [s.sm_down]: { display: 'none' } }}>
                      <Img
                        style={sImage2Desktop}
                        fluid={tab.images[1].image.childImageSharp.fluid}
                        alt={tab.titles[0].name}
                      />
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
        {/* /////////////////////// */}
      </div>
    </WrapperSection>
  )
}

const sContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  transition: 'opacity 0.6s ease-out',

  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    marginLeft: globals.spacing.outside.mobile,
    marginRight: globals.spacing.outside.mobile,
    borderRadius: globals.roundness.sections.mobile,
  },
  [s.xs]: {
    boxShadow: globals.shadows.sections.mobile,
    borderRadius: globals.roundness.sections.mobile,
    margin: 0,
  },
  [s.md]: {
    padding: globals.spacing.inside.desktop,
    paddingTop: 0,
    boxShadow: globals.shadows.sections.desktop,
    margin: '0 auto',
    borderRadius: globals.roundness.sections.desktop,
  },
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container,
})

const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '2rem',
  },
  [s.md]: { padding: '0 2rem', marginBottom: '4rem' },
}

const sTabs = {
  '.inidian-scroll': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [s.sm_down]: {
      marginTop: globals.spacing.inside.mobile,
      paddinRight: '1rem',
    },
    [s.md]: { marginTop: globals.spacing.inside.desktop / 2 },
    overflowX: 'scroll',
  },
}

const sTabContainer = {
  transition:
    'color 0.25s ease-out, background-color 0.25s ease-out, filter 0.25s ease-out, opacity 0.25s ease-out',
  borderRadius: globals.roundness.buttons,
  height: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textTransform: 'uppercase',
  width: 'max-content',
  fontWeight: 700,
  [s.sm_down]: {
    padding: '0 1.5rem',
    marginLeft: '1.5rem',
    marginRight: '-0.5rem',
    height: 56,
  },
  [s.md]: {
    padding: '0 2rem',
    margin: '0 1rem',
    height: 64,
  },
  '.sTabTextSmaller': {
    fontSize: 12,
    letterSpacing: '0.25em',
    opacity: 0.2,
    transition: 'opacity 0.25s ease-out',
  },
  '.sTabTextLarger': {
    lineBreak: 'no-break',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    letterSpacing: '- 0.025em',
    opacity: 0.4,
    transition: 'opacity 0.25s ease-out',
    [s.sm_down]: { fontSize: 14 },
    [s.md]: { fontSize: 18 },
  },
  '&:hover': {
    cursor: 'pointer',
    [s.hover]: {
      '.sTabTextSmaller, .sTabTextLarger': { opacity: 0.6 },
    },
  },
  active: {
    opacity: 1,
    '.sTabTextSmaller': { opacity: 0.6 },
    '.sTabTextLarger': { opacity: 1 },
    '&:hover': {
      [s.hover]: {
        // '.sTabTextSmaller, .sTabTextLarger': { opacity: 1 },
        // filter: 'grayscale(1)',
        // opacity: 0.6,
      },
    },
  },
}

const sBottomContainer = {
  display: 'flex',
  justifyConten: 'space-between',
  width: '100%',
  [s.xs]: {
    marginTop: globals.spacing.inside.mobile,
    flexDirection: 'column',
    padding: globals.spacing.inside.mobile,
  },
  [s.sm_only]: {
    marginTop: globals.spacing.inside.mobile,
    flexDirection: 'column',
    padding: globals.spacing.inside.mobile,
  },
  [s.md]: { marginTop: globals.spacing.inside.desktop },
  // animation: 'appear 0.6s both alternate ease-out',
  // '@keyframes appear': {
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  // },
}

const sLeftContainer = {
  // backgroundColor: 'rgba(0,0,255, 0.2)',
  [s.xs]: { width: '100%' },
  [s.sm]: { width: '100%' },
  [s.md]: { width: '50%', minHeight: 350 },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const sRightContainer = {
  // backgroundColor: 'rgba(0,255,0, 0.2)',
  [s.xs]: {
    width: '100%',
  },
  [s.sm_down]: {
    width: '100%',
  },
  // [s.sm_down]: {
  //   width: '50%',
  // },
  [s.md]: { width: '50%' },
  position: 'relative',
}

// const sImage1Mobile = {
//   width: '100%',
//   height: '100%',
//   margin: `0 ${globals.spacing.inside.mobile}`,
//   position: 'absolute',
//   top: 0,
//   left: '50%',
//   transform: 'translateX(-50%)',
//   borderRadius: globals.roundness.pictures.desktop,
//   boxShadow: `0px 8px 32px ${colors.black.concat(alpha[8])}`,
// }

// const sImage1Desktop = {
//   width: 350,
//   height: 350,
//   position: 'absolute',
//   top: 0,
//   right: 0,
//   borderRadius: globals.roundness.pictures.desktop,
//   boxShadow: `0px 8px 32px ${colors.black.concat(alpha[8])}`,
// }

const sImage1 = {
  [s.xs]: {
    width: `calc(100vw - ${globals.spacing.outside.mobile * 2}px)`,
    height: `calc(100vw - ${globals.spacing.outside.mobile * 2}px)`,
    position: 'absolute',
    borderRadius: globals.roundness.pictures.desktop,
    boxShadow: `0px 8px 32px ${colors.black.concat(alpha[8])}`,
  },
  [s.sm_only]: {
    width: `calc(100vw - ${globals.spacing.outside.mobile * 2}px)`,
    height: 350,
    top: 0,
    left: -globals.spacing.outside.mobile,
    position: 'absolute',
    borderRadius: globals.roundness.pictures.desktop,
    boxShadow: `0px 8px 32px ${colors.black.concat(alpha[8])}`,
  },
  [s.md]: {
    width: 350,
    height: 350,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: globals.roundness.pictures.desktop,
    boxShadow: `0px 8px 32px ${colors.black.concat(alpha[8])}`,
  },
}

const sImage2Desktop = {
  height: 320,
  width: (320 * 9) / 16,
  maxWidth: '100vw',
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(50%, 25%)',
  borderRadius: globals.roundness.pictures.mobile,
  boxShadow: `0px 16px 64px ${colors.black.concat(alpha[16])}`,
}

// const sImage2Mobile = {
//   height: 160,
//   width: 160,
//   position: 'absolute',
//   bottom: 0,
//   left: globals.spacing.inside.mobile,
//   transform: 'translate(0%, 50%)',
//   borderRadius: globals.roundness.pictures.mobile,
//   boxShadow: `0px 16px 64px ${colors.black.concat(alpha[16])}`,
// }

// const sTools = {
//   display: 'flex',
//   justifyContent: 'flex-start',
//   alignItems: 'center',
//   [s.sm_down]: {
//     marginTop: globals.spacing.inside.mobile / 2,
//     marginBottom: globals.spacing.inside.mobile * 2,
//   },
//   [s.md]: { marginTop: globals.spacing.inside.desktop / 2 },

//   img: {
//     height: 32,
//     marginRight: '2rem',
//   },
// }

const sDescriptionTheme = (theme) => ({
  marginBottom: 32,
  opacity: 0.6,
})

export default TabServices
